/* Sidebar */
.sidebar {
  position: fixed;
  padding-left: 50px;
  height: 100%;

  // min-height: calc(100vh - #{$navbar-height}); //change the fulll width of the side menu
  min-height: calc(100vh); 
  background: #ffffff;

//   background: linear-gradient(135deg, #6190E820, #A7BFe810);
// backdrop-filter: blur( 0px );
// -webkit-backdrop-filter: blur( 0px );
// border: 1px solid rgba( 255, 255, 255, 0.18 );

  // box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  // font-family: $type1;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 999;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  @media (min-width: 1366px) {
    width: $sidebar-width-xl;
  }
  .sidebar-brand-wrapper {
    width: auto;
    height: $navbar-height;
    @media (max-width: 992px) {
      display: none !important;
    }
    .sidebar-brand {
      // padding: 2.25rem 0 0.25rem 40px;
      text-align: center;
      display: inline-block;
      margin:0 auto 0 17px;

      .rtl & {
        padding: 20px 10px 10px 0;
      }

      img {
        width: calc(#{$sidebar-width-lg} - 80px);
        max-width: 100%;
        height: 48px;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      display: none;
      img {
        width: calc(#{$sidebar-width-icon} );
        max-width: 100%;
        // height: 35px;
        margin: auto;
      }
    }
  }
  >.nav {
    padding: 0;
    padding-top: 80px;
    // margin-top: 20px;
    // justify-content: center;
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    // margin: 20px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    .nav-item {
      .collapse {
        z-index: 999;
      }

      .sub-menu {
        background-color: transparent;
        margin: 0 0;
        flex-wrap: nowrap;
        padding: 15px 0;

        .rtl & {
          padding: $rtl-sidebar-submenu-padding;
        }

        .nav-item {
          display: block;
          max-width: 100%;
          .nav-link {
            padding: 10px 0px 0px 35px; 
            position: relative;
            color: rgba($sidebar-light-menu-color, 0.8);
            font-size: 14px;
            font-weight: 500;
            transition-duration: 0.5s;
            transition-timing-function: "ease-in-out";
            transition-property: "background", "color", "box-shadow", "padding";
            display: block;
            max-width: 100%;
            display: block;
            @media (max-width:768px) {
              color: #7a7a7a;
            }

            &:before {
              // content: "";
              position: absolute;
              top: 0px;
              left: 35px;
              display: block;
              height: 100%;
              width: 2px;
              background: $sidebar-light-submenu-line-color;

              .rtl & {
                right: -5px;
                left: unset;
              }
            }

            &:hover {
              color: $sidebar-light-menu-color;

              &:before {
                background: #fff;
              }
            }

            .rtl & {
              padding: 10px 15px 10px 0px;
            }
          }

          &:first-child {
            .nav-link {
              padding-top: 0;
            }
          }

          &:last-child {
            .nav-link {
              padding-bottom: 0;
            }
          }
        }
      }

      .nav-link {
        align-items: center;
        display: flex;
        padding: $sidebar-menu-padding;
        white-space: nowrap;
        color: $sidebar-nav-item-link-color;

        .rtl & {
          padding: $sidebar-menu-padding-rtl;
        }

        i {
          &.menu-arrow {
            margin-left: auto;
            margin-right: 0;
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-in;

            &:before {
              content: "\F054";
              font-family: "Material Design Icons";
              font-size: 18px;
              line-height: 1;
              font-style: normal;
              vertical-align: middle;
              color: $sidebar-light-menu-arrow-color;
            }
          }
        }

        &[aria-expanded="true"] {

          i {
            &.menu-arrow {
              transform: rotate(90deg);
            }
          }
        }

        .menu-icon {

          position: absolute;
    left: 13px;
    top: 13px;

          // display: block;
          // margin-right: 1.25rem;
          // width: $sidebar-icon-size;
          // line-height: 1;
          // font-size: 16px;
          // color: $sidebar-nav-item-link-color;

          .rtl & {
            margin-right: 0;
            margin-left: 1.25rem;
          }
        }

        .menu-title {

          position: static;
          left: 70px;
          top: 0px;
          display: block;
          background-color: transparent;
          padding: 10px;
          color: #666;
          height: auto;
          line-height: 16px;
          width: 100%;
          text-align: left;
          margin-left: 7px;
          font-weight: normal;
          word-wrap: break-word;
          white-space: normal

          // color: inherit;
          // display: inline-block;
          // font-size: $sidebar-menu-font-size;
          // line-height: 1;
          // vertical-align: middle;
          // font-weight: 500;
        }

        .badge {
          margin-left: auto;
        }

        &:hover {
          color: $sidebar-navlink-menu-item-hover-color;
        }
      }

      &.active {
        //background: $sidebar-light-menu-active-bg;
        >.nav-link {
          color: $sidebar-light-menu-active-color;
         // opacity: 0.8;
          .menu-icon {
            width: 35px;
            height: 35px;
            background: #363636;
            border-radius: 15px;
            display: inline-block;
            line-height: 40px;
            color: #fff;
            position: absolute;
            text-align: center;
            
            }
            .menu-icon::before{
              content: '';
              position: absolute;
              width: 46px;
              height: 26px;
              left: -2px;
              top: 22px;
              content: '';
              background: #36363690;
              /* opacity: 0.8; */
              -webkit-filter: blur(10px);
              filter: blur(10px);
              border-radius: 15px;
              
          }
        
          .menu-title{color: #242424;padding-left: 30px;padding-top: 15px;}
          
        }
      }

      &.nav-profile {
        max-width: 270px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;
        margin-bottom: 30px;

        .nav-link {
          display: flex;
          border-radius: 3px;
          border: $sidebar-nav-item-profile-border-color;
          background-color: $sidebar-nav-item-profile-bg;
          color: $sidebar-nav-item-profile-color;
          padding: 15px 25px;

          .profile-image {
            margin-right: 15px;
            position: relative;

            .rtl & {
              margin-right: 0;
              margin-left: 15px;
            }
          }

          .dot-indicator {
            position: absolute;
            bottom: 0px;
            right: 0px;
          }

          .profile-name {
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 15px;
            color: $sidebar-profile-name-color;
          }

          .designation {
            margin-bottom: 0;
            font-weight: 400;
            color: $sidebar-profile-designation-color;
          }
        }
      }

      &.nav-category {
        color: $sidebar-light-category-color;
        border-bottom: 1px solid $sidebar-nav-category-border-color;
        margin: 0 30px;
        font-size: 15px;
        font-weight: 500;
        padding: 10px 0px 15px 0px;
      }
    }

    >.nav-item {
      >.nav-link {
        height: $nav-link-height;
      }
    }

    >.nav-item:not(.nav-profile) {
      >.nav-link {
        position: relative;

        &:before {
          // content: "";
          // position: absolute;


          .rtl & {
            left: auto;
            right: 45px;
          }
        }
      }
    }

    &:not(.sub-menu) {
      >.nav-item {
        >.nav-link {

          color: #666;
          font-size: 14px;
          text-decoration: none;
          transition: 0.2s ease;
          padding: 28px;
          // list-style: none;
          // text-align: center;
          // position: relative;
          // margin-bottom: 15px;
          // transition: 0.2s ease;



        }

        &:hover {
          &:not(.nav-profile):not(.hover-open) {
            >.nav-link:not([aria-expanded="true"]) {
              // background: $sidebar-light-menu-hover-bg;
            //  background: #131e26;
            
              padding-left: 30px;

              .rtl & {
                padding-left: 30px;
                padding-right: 80px;
              }

              &:before {
                border-color: $sidebar-light-menu-hover-color;
              }

              .menu-title {
                color: $sidebar-light-menu-hover-color;
                color: black;
              }

              .menu-arrow {
                &:before {
                  color: $sidebar-light-menu-arrow-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: 0;
    bottom: 0;
    overflow: auto;
  //  left: -$sidebar-width-lg;
    transition: all 0.25s ease-out;

    &.active {
      left: 0;
      z-index: 9999;
    }
  }
}


@media screen and (max-width: 991px) { 
  .sidebar > .nav .nav-item .sub-menu .nav-item .nav-link { padding: 5px 0px 5px 49px;}
  // .sidebar > .nav .nav-item .sub-menu{padding-top: 0;display: none;}
  .collapse{
   
    right: 0px;
    width: 100%;
    background: #fff;
    .navbar-nav-left{
      float: left;
    }
    .navbar-nav-right{
      float: right; margin-right: 10px;
      
    }
    .Common_langContainer__n_RzJ .Common_langFlag__W216n {
      width: 17px;
  }
}

.arrow-icon{
  position: absolute;
  right: 10px;
  top:15px;
}

@media screen and (max-width:767px) { 
  .navbar-nav.navbar-nav-right.header-links{
    .preview-list.dropdown-menu{top: 50px!important;}
  }

}}

@media (max-width:991px) {
  .sidebar{background-color: #fff;left: -1500px;transition: 0.2s ease;box-shadow: 10px 0px 40px #00000030;z-index: 999;}
  .sidebar-icon-only .sidebar{left: 0;}
  .sidebar{width: 80%;}
}

// @media (max-width:991px) {
//   .p-3{padding: 0 !important;}
//   .sidebar > .nav .nav-item .nav-link .menu-title{display: none !important;}
 
//     .sidebar > .nav .nav-item:hover .nav-link .menu-title{
//     display: block !important;
//     position: absolute !important;
//     left: 44px !important;
//     width: 150px !important;
//     padding-left: 20px !important;
//     background-color: #fff !important;
//     box-shadow: 0px 0px 10px #ccc;
//     padding-top: 15px;
//     z-index: 10 !important;}

//     .sidebar > .nav .nav-item:hover .collapse{
//       display: block !important;
//       position: absolute;
//       background-color: #fff !important;
//       box-shadow: 0px 0px 10px #ccc;
//       margin-left: 0px;
//       margin-top: -16px;
//       width: 75%;
//     }
//     .sidebar > .nav .nav-item .sub-menu .nav-item .nav-link {
//       padding: 5px 0px 5px 11px;
//       position: relative;
//   }
//   .arrow-icon{right: inherit;left: 50px;  }
// }
// @media (max-width:998px) {
// .sidebar > .nav{overflow: visible !important;}
// .sidebar-offcanvas{overflow: visible; width: 70px;}
// .sidebar > .nav .nav-item:hover .collapse{width: 149px;left: 59px;}
// .sidebar > .nav:not(.sub-menu) > .nav-item > .nav-link{padding: 25px 28px;}
  
// }
