@import '../../style/custonVariable';
.subcontainercard{
    padding: 15px;
    width: "100%";
    height: '100%';
    // margin:10%;
    min-height: 300px;
  
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
background: #fff;
margin-top: 50;
//     h4{
// text-align: center;
//     }
//     h2{
//         text-align: center;
//     }
//     .requirementssec{
//         display: "flex";
//         flex-direction: "row";
//         justify-content: "space-between";
    
//      } 


  }
.commisionhead{
  font-size: 20;
  font-weight: 700;
}

  .commisiongrid{
    display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

  .subcontainercarddark{
    padding: 20px;
    width: "100%";
    height: '100%';
    // margin:10%;
    min-height: 300px;
  
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
background: #0F2036 ;
//     h4{
// text-align: center;
//     }
//     h2{
//         text-align: center;
//     }
//     .requirementssec{
//         display: "flex";
//         flex-direction: "row";
//         justify-content: "space-between";
    
//      } 
  }
   
  .subcontainercard:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    // transform: scale(1.01);
   
      

}