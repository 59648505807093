@import '../../style/custonVariable';
.MainContainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 600px) { 
        margin-top: 3px;
      }
    
    .summary{
        padding: 20px;
        position: relative;
        // margin-bottom: 50px;
        .tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: 25px;
            background: #fff;
            // border-radius: 1px;  
            // border-radius: 2%;  
         
        }  
        .filterSection {

            display: flex !important;
    justify-content: flex-end !important;
    gap: 20px !important;

            @media (min-width: 920px) {
                margin-left: 50px;

            }

            padding: 20;
            ._3vt7_Mh4hRCFbp__dFqBCI input {
                font-size: 15px;
            }
        
            display: inline;
        
            .dark_mode_DateRangePicker {
                background: #14243A !important;
                cursor: pointer;
                padding: 5px;
                border: 1px solid #ccc;
                min-width: 10%;
                max-width: max-content;
                display: inline-block;
                margin-right: 5px;
                font-size: 15px;
        
                @media (max-width: 767px) {
                    margin-bottom: 5px;
                    width: 100%;
                    max-width: none;
                }
            }
        
            @media (max-width: 767px) {
                display: block;
                flex-direction: column;
    gap: 10px;
            }
        
            .filterButton {
                display: inline;
                // margin: 0px 10px 0px 10px;
        
                @media (max-width: 767px) {
                    display: block;
                }
        
                .padding5 {
                    padding: 10px;
                    margin-right: 3px;
                    margin-left: 5px;
                }
        
                @media (max-width:600px) {
                    margin-right: 8px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                }
            }
        
            ._2iA8p44d0WZ-WqRBGcAuEV {
                margin-bottom: 10px !important;
            }
        
            .exportButton {
                display: inline;
                float: right;
        
                button {
                    background-color: $color-primary;
                    height: $input-height;
                    border-color: $color-primary;
                    border-radius: 0.1875rem;
                    margin-right: 0.333em;
                    margin-bottom: 0.333em;
                    margin-top: 10px;
                    ;
        
                    @media (max-width:600px) {
                        margin-right: 8px;
                        margin-top: 10px;
                        margin-bottom: 5px;
                    }
        
                    i {
                        text-align: center;
                        background-color: rgba(0, 0, 0, 0.1);
                        border-radius: 2px 0 0 2px;
                        position: relative;
                        float: left;
                        width: 30px;
                        height: 30px;
                        margin: -6px -12px;
                        margin-right: -12px;
                        margin-right: -12px;
                        margin-right: 3px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
} 
.buttonhover:hover{
    // box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.4);
    transform: scale(1.10);

}
.imghover:hover{
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(2.01);
    

}
.texthover:hover{
    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.4);
    transform: scale(1.10);

}
.tabledetail{
    color:"#00000090"
}
.tabledetail_dark{
    color:"#ffffff"
}
.subcontainercard{
    margin: 20px;
    padding: 20px;
    width: "100%";
    height: '100%';
    // margin:10%;
    min-height: 300px;
  
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
background: #fff;
margin-top: 50;
    h4{
text-align: center;
    }
    h2{
        text-align: center;
    }
    .requirementssec{
        display: "flex";
        flex-direction: "row";
        justify-content: "space-between";
    
     } 
  }
  .subcontainercarddark{
    margin: 20px;
    padding: 20px;
    width: "100%";
    height: '100%';
    // margin:10%;
    min-height: 300px;
  
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
background: #0F2036 ;
    h4{
text-align: center;
    }
    h2{
        text-align: center;
    }
    .requirementssec{
        display: "flex";
        flex-direction: "row";
        justify-content: "space-between";
    
     } 
  }
  .circular{
    display: flex;
    justify-content: center;
    padding: 10;
   
}
.progress{
width:100%;
align-self: center;


}
.textalign{
    text-align: center;
}
.progressbarcontainer{
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    margin: 10;
padding:10

}
  
  .subcontainercard:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
   
      

}
.subcontainercard {
    background: "#D8D9DA";
    // background: rgba( 255, 255, 255, 0.25 );
// box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
// backdrop-filter: blur( 4px );
// -webkit-backdrop-filter: blur( 4px );
// border-radius: 10px;
// border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
   
 


  .subcontainercarddark:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
    // .card {
     
    //   }
      

}



/*********************************** DARK MODE ************************************/

.MainContainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 600px) { 
        margin-top: 3px;
      }
   
  
    .summary{
        padding: 20px;
        position: relative;
        .dark_mode_tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: 25px;
            background: #0F2036 !important;
            // border-radius: 1px;  
            // border-radius: 2%;  
            .dataTable{
                padding-top: 15px;
                .tables{
                    padding-top: 10px;
                    font-family: system-ui;
                   
                }
               
            }}}}
          
           

/******************************** END OF DARK MODE *******************************/